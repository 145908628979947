import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import homecontents from "./homecontents";
import "../../styles.css";

const HomepageContents = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <div>
      {homecontents.map((homecontent, index) => (
        <Row key={index} className="homecard-margin">
          {index % 2 === 0 ? (
            <>
              <Col
                className="order-1 px-0 d-flex align-items-center align-items-stretch"
                xl={6}
                lg={12}
              >
                <Image
                  src={homecontent.contentImg}
                  className="homecontent-img"
                  fluid
                />
              </Col>
              <Col
                className="homecardbg-txt order-2 d-flex flex-column justify-content-center"
                xl={6}
                lg={12}
              >
                <h3>{homecontent.title}</h3>
                {homecontent.description.split("\n").map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
                {homecontent.title === "Clearance centre" ? (
                  <a
                    href={homecontent.destination}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="homepage-button">
                      {homecontent.buttonTxt}
                    </Button>
                  </a>
                ) : (
                  <Link to={homecontent.destination} onClick={scrollToTop}>
                    <Button className="homepage-button">
                      {homecontent.buttonTxt}
                    </Button>
                  </Link>
                )}
              </Col>
            </>
          ) : (
            <>
              <Col
                className="order-2 px-0 d-flex align-items-center align-items-stretch"
                xl={6}
                lg={12}
              >
                <Image
                  src={homecontent.contentImg}
                  className="homecontent-img"
                  fluid
                />
              </Col>
              <Col
                className="homecardbg-txt order-1 d-flex flex-column justify-content-center"
                xl={6}
                lg={12}
              >
                <h3>{homecontent.title}</h3>
                {homecontent.description.split("\n").map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
                {homecontent.title === "Clearance centre" ? (
                  <a
                    href={homecontent.destination}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button className="homepage-button">
                      {homecontent.buttonTxt}
                    </Button>
                  </a>
                ) : (
                  <Link to={homecontent.destination} onClick={scrollToTop}>
                    <Button className="homepage-button">
                      {homecontent.buttonTxt}
                    </Button>
                  </Link>
                )}
              </Col>
            </>
          )}
        </Row>
      ))}
    </div>
  );
};

export default HomepageContents;
