import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import products from "./products";
import "../../styles.css";

const ProductpageCarousel = () => {
  const { slug } = useParams();
  const product = products.find((p) => p.slug === slug);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="productPageCarouselContainer">
      <Carousel
        activeIndex={index}
        onSelect={handleSelect}
        data-bs-theme="dark"
        className="productPage-img"
        indicators={false}
      >
        {product.slideImgs.map((image, idx) => (
          <Carousel.Item key={idx}>
            <img
              src={image.src}
              alt={`Product ${idx + 1}`}
              className="d-block w-100 img-fluid"
            />
            <p className="productPage-caption">{image.caption}</p>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="product-thumbnail-scroll-container">
        <div className="product-thumbnail-container">
          {product.slideImgs.map((image, idx) => (
            <img
              key={idx}
              src={image.src}
              alt={`Thumbnail ${idx}`}
              className={`product-thumbnail ${idx === index ? "active" : ""}`}
              onClick={() => setIndex(idx)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductpageCarousel;
