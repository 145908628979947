import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ProjectspageScrollButton from "../components/projectspage-components/ProjectspageScrollButton";
import ProjectspageCarousel from "../components/projectspage-components/ProjectspageCarousel";
import "../styles.css";

const Projects = () => {
  return (
    <div className="page-content">
      <Row>
        <Col>
          <h2>Projects</h2>
          <p>
            From design and planning, to fit-out and installation, we've worked
            on countless projects over the years. Across busy offices, break-out
            spaces, canteens and meeting rooms, there's nothing better than
            watching a vision come to life.
          </p>
          <p>Below are some recent examples.</p>
          <ProjectspageScrollButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <ProjectspageCarousel />
        </Col>
      </Row>
    </div>
  );
};

export default Projects;