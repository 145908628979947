import React from "react";
import AboutpageContents from "../components/aboutpage-components/AboutpageContents";
import "../styles.css";

const About = () => {
  return (
    <div className="page-content">
      <h2>Who We Are</h2>
      <AboutpageContents />
    </div>
  );
};

export default About;
