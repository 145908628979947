import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles.css";

const HoverIcon = ({ icon: Icon }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div className="icon-container">
      <Icon
        className={`icon ${hovered ? "yellow" : "white"}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

HoverIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
};

export default HoverIcon;
