import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import ContactFormAlert from "./ContactFormAlert";
import "../../styles.css";

const ContactForm = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    orderNumber: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [mailingList, setMailingList] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleCloseAlert = () => setShowAlert(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      setAlertMessage("Please fill in all required fields");
      setShowAlert(true);
      return;
    }

    try {
      const dataWithMailingList = { ...formData, mailingList };

      // Set CORS headers in the request
      const response = await axios.post(
        "https://u1ou1rn0h3.execute-api.eu-west-2.amazonaws.com/prod/submit-form", // Update with your API Gateway endpoint (old working link was https://d3tnvheycz8rx.cloudfront.net/prod/submit-form)
        dataWithMailingList,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Form submission response:", response.data);
      setAlertMessage("Thank you for contacting us, we will get back to you as soon as possible.");
      setShowAlert(true);
      resetForm();
    } catch (error) {
      console.error("Form submission error:", error);
      setAlertMessage("Error submitting form. Please try again later.");
      setShowAlert(true);
    }
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setMailingList(true);
  };

  return (
    <div>
    <Form onSubmit={handleSubmit}>
      {/* Form fields */}
      {/* Name field */}
      <Form.Group controlId="formName">
        <Form.Label className="mt-2"><b>Name *</b></Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your name"
          name="name"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          className="contactform-text"
        />
      </Form.Group>

      {/* Email field */}
      <Form.Group controlId="formEmail">
        <Form.Label className="mt-3"><b>Email *</b></Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          className="contactform-text"
        />
      </Form.Group>

      {/* Phone field */}
      <Form.Group controlId="formPhone">
        <Form.Label className="mt-3"><b>Phone</b></Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your phone number"
          name="phone"
          value={formData.phone}
          onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          className="contactform-text"
        />
      </Form.Group>

      {/* Order Number field */}
      <Form.Group controlId="formOrderNumber">
        <Form.Label className="mt-3"><b>Order Number</b></Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your order number"
          name="orderNumber"
          value={formData.orderNumber}
          onChange={(e) => setFormData({ ...formData, orderNumber: e.target.value })}
          className="contactform-text"
        />
      </Form.Group>

      {/* Message field */}
      <Form.Group controlId="formMessage">
        <Form.Label className="mt-3"><b>Message *</b></Form.Label>
        <Form.Control
          as="textarea"
          rows={4}
          placeholder="Enter your message"
          name="message"
          value={formData.message}
          onChange={(e) =>
            setFormData({ ...formData, message: e.target.value })}
          className="contactform-text"
        />
      </Form.Group>

      {/* Mailing list checkbox */}
      <Form.Group controlId="formMailingList" className="mt-4">
        <Form.Check
          label="Subscribe to the Adato mailing list"
          type="checkbox"
          checked={mailingList}
          onChange={(e) => setMailingList(e.target.checked)}
          className="contactform-text"
        />
      </Form.Group>
      
      {/* Required fields text */}
      <div className="mt-3"> <i><b>*</b> Required fields</i></div>


      {/* Submit button */}
      <Button type="submit" className="contact-button">
        Submit
      </Button>
    </Form>

    {/* Alert modal */}
    <ContactFormAlert
        show={showAlert}
        handleClose={handleCloseAlert}
        message={alertMessage}
      />
    </div>
  );
};

export default ContactForm;

