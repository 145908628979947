import React from "react";
import { Navbar, Container } from "react-bootstrap";
import {
  FaLinkedin,
  FaInstagram,
  FaHome,
  FaEbay,
  FaPhone,
  FaMailBulk,
} from "react-icons/fa";
import HoverIcon from "./HoverIcon";
import { Link } from "react-router-dom";
import "../styles.css";

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <Navbar className="footer-bgcolor" variant="dark" sticky="top">
      <Container className="d-flex flex-column flex-md-row justify-content-between align-items-center">
        {/* First group of icons */}
        <div className="d-flex justify-content-center flex-wrap mt-2 mt-md-0">
          <Link to="/" className="social-icons" onClick={scrollToTop}>
            <HoverIcon icon={FaHome} />
          </Link>
          <a
            href="https://uk.linkedin.com/company/adato-ltd"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icons"
          >
            <HoverIcon icon={FaLinkedin} />
          </a>
          <a
            href="https://www.instagram.com/adato_uk/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icons"
          >
            <HoverIcon icon={FaInstagram} />
          </a>
          <a
            href="https://www.ebay.co.uk/usr/adatoofficefurnitureclearance"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icons"
          >
            <HoverIcon icon={FaEbay} />
          </a>
        </div>
        {/* Second group of icons and content */}
        <div className="d-flex justify-content-center align-items-center flex-wrap mt-3 mt-md-0">
          <Link
            to="/CustomerServices"
            className="social-icons"
            onClick={scrollToTop}
          >
            <HoverIcon icon={FaPhone} />
          </Link>
          <span className="quick-contact">
            +44 7375122636 || enquiries@adato.co.uk
          </span>
          <Link
            to="/CustomerServices"
            className="social-icons"
            onClick={scrollToTop}
          >
            <HoverIcon icon={FaMailBulk} />
          </Link>
        </div>
        <div className="my-2">
          <hr className="d-block d-md-none" />{" "}
          {/* Line break visible on extra small screens */}
          <p className="copyright-txt">
            © {new Date().getFullYear()} Copyright: Adato. All rights reserved.
          </p>
        </div>
      </Container>
    </Navbar>
  );
}

export default Footer;
