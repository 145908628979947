import React from "react";
import ProductCard from "../components/productpage-components/ProductCard";
import "../styles.css";

const Products = () => {
  return (
    <div className="page-content">
      <h2>Product Ranges</h2>
      <ProductCard />
    </div>
  );
};

export default Products;
