const homecontents = [
  {
    title: "Products",
    description:
      "A wealth of trusted, enduring connections. A knowledge of the industry and it's ever-changing trends. Adato are able to offer a portfolio of seating to meet any office requirement.\nWe currently offer a range of formal office seating, desk/storage systems, and lounge/reception furniture that we are very proud of.",
    buttonTxt: "Our Range",
    destination: "/Products",
    contentImg: "/images/Homepageimgs/ProductHome.png",
  },
  {
    title: "About",
    description:
      "We are a UK based Office Furniture Manufacturer with over 40 years of experience. A bespoke, family business built upon close working relationships, we aim to deliver the highest quality service. As well as our standard range of products, we also offer procurement services for any of you furniture needs. \nFind out more about our story here…",
    buttonTxt: "About Us",
    destination: "/About",
    contentImg: "/images/Homepageimgs/AboutHome.jpg",
  },
  {
    title: "Projects",
    description:
      "From design and planning, to fit-out and installation, we've worked on countless projects over the years. Across busy offices, break-out spaces, canteens and meeting rooms, there's nothing better than watching a vision come to life. Here you can find some recent examples.",
    buttonTxt: "Learn More",
    destination: "/Projects",
    contentImg: "/images/Homepageimgs/ProjectsHome.jpg",
  },
  {
    title: "Clearance centre",
    description:
      "For our retail customers, we have an eBay clearance shop for all our end-of-line and ex-showroom models. Grab a bargain below!",
    buttonTxt: "Clearance Products",
    destination: "https://www.ebay.co.uk/usr/adatoofficefurnitureclearance",
    contentImg: "/images/Homepageimgs/EbayHome.jpg",
  },
];

export default homecontents;

// {
//     title: '',
//     description: "",
//     buttonTxt: '',
//     destination: '',
//     contentImg: '',
// },
