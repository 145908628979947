import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa"
import "../../styles.css";

const ContactFormAlert = ({ show, handleClose, message }) => {
    const AlertIcon = message.includes("Thank you") ? FaCheckCircle : FaExclamationCircle;
    const alertIconClass = message.includes("Thank you") ? "alertIcon-check" : "alertIcon-exclaim";

  return (
    <Modal show={show} onHide={handleClose} centered >
      <Modal.Header className="alertCF-title">
        <Modal.Title><AlertIcon className={alertIconClass}/></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="alertCF-content">{message}</div></Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} className="alertCF-button">
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactFormAlert;
