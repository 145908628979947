import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import products from "../components/productpage-components/products";
import ProductpageCarousel from "../components/productpage-components/ProductpageCarousel";
import ProductpageAccordion from "../components/productpage-components/ProductpageAccordian";
import "../styles.css";

const Productpage = () => {
  const { slug } = useParams();
  const product = products.find((p) => p.slug === slug);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="page-content">
      <Row>
        <div>
          <h2>{product.name}</h2>
          <Row>
            <Col lg={6} md={12}>
              <ProductpageCarousel />
            </Col>
            <Col lg={6} md={12}>
              <ProductpageAccordion />
            </Col>
          </Row>
          {product.situImg && (
            <Row>
              <Col lg={12} md={12}>
                <img
                  src={product.situImg}
                  alt="Product in situ"
                  className="situ-img"
                />
              </Col>
            </Row>
          )}
        </div>
      </Row>
    </div>
  );
};

export default Productpage;
