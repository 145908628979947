import React from "react";
import HomepageCarousel from "../components/homepage-components/HomepageCarousel";
import HomepageContents from "../components/homepage-components/HomepageContents";
import "../styles.css";

const Home = () => {
  return (
    <div className="page-content">
      <HomepageCarousel />
      <HomepageContents />
    </div>
  );
};

export default Home;
