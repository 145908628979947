import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import MyNavBar from "./components/MyNavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Productpage from "./pages/Productpage";
import Projects from "./pages/Projects";
import CustomerServices from "./pages/CustomerServices";
import Login from "./pages/Login";
import Register from "./pages/Register";

const App = () => {
  return (
    <>
      <MyNavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Products" element={<Products />} />
        <Route path="/Products/:slug" element={<Productpage />} />
        <Route path="/Projects" element={<Projects />} />
        <Route path="/CustomerServices" element={<CustomerServices />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
