import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import products from "./products";
import "../../styles.css";

const ProductCard = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [titleHoveredIndex, setTitleHoveredIndex] = useState(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
    setTitleHoveredIndex(null);
  };

  return (
      <Row xs={1} sm={1} md={1} lg={2} xl={4} className="g-4">
        {products.map((product, index) => (
          <Col key={index} xs={12} sm={6} md={6} lg={6} xl={4}>
            <Card
              border="light"
              className="pcard-bg"
              text="black"
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            >
              <Link to={`/Products/${product.slug}`} onClick={scrollToTop}>
                <div className="d-flex justify-content-center position-relative">
                  <Card.Img
                    className={`product-img ${
                      hoveredIndex === index ? "img-hover" : ""
                    }`}
                    variant="top"
                    src={product.rangeImg}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Link>
              <Link
                to={`/Products/${product.slug}`}
                onClick={scrollToTop}
                className="card-link"
                onMouseOver={() => setTitleHoveredIndex(index)}
                onMouseOut={() => setTitleHoveredIndex(null)}
              >
                <Card.Title
                  className={`ptitle-name ${
                    titleHoveredIndex === index ? "ptitle-hover" : ""
                  }`}
                >
                  {product.name}
                </Card.Title>
              </Link>
              {hoveredIndex === index && (
                <Card.Text className="text-start">
                  {product.shortDescription}
                </Card.Text>
              )}
            </Card>
            <br />
          </Col>
        ))}
      </Row>
  );
};

export default ProductCard;
