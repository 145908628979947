import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import products from "./products";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/button";
import "../../styles.css";

const ProductpageAccordian = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  const { slug } = useParams();
  const product = products.find((p) => p.slug === slug);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <Accordion
      defaultActiveKey={["0"]}
      alwaysOpen
      className="txtalign-left"
      flush
    >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Description</Accordion.Header>
        <Accordion.Body>
          {product.longDescription.split("\n").map((paragraph, i) => (
            <p key={i}>{paragraph}</p>
          ))}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Standard Features</Accordion.Header>
        <Accordion.Body>
          {product.features.map((feature, index) => (
            <div key={index}>
              {feature.name === feature.name.toUpperCase() ? (
                <p style={{ fontWeight: "bold" }}>
                  {feature.image && (
                    <img
                      src={feature.image}
                      alt={feature.name}
                      style={{
                        maxHeight: "2.5em",
                        marginRight: "5px",
                        verticalAlign: "middle",
                      }}
                    />
                  )}
                  {feature.name}
                </p>
              ) : (
                <ul>
                  <li>
                    {feature.image && (
                      <img
                        src={feature.image}
                        alt={feature.name}
                        style={{
                          maxHeight: "2.5em",
                          marginRight: "5px",
                          verticalAlign: "middle",
                        }}
                      />
                    )}
                    {feature.name}
                  </li>
                </ul>
              )}
            </div>
          ))}
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Optional Extras + Fabric & Range Colour options
        </Accordion.Header>
        <Accordion.Body>
          {product.extras.map((extra, index) => (
            <div key={index}>
              {extra.name === extra.name.toUpperCase() ? (
                <p style={{ fontWeight: "bold" }}>
                  {extra.image && (
                    <img
                      src={extra.image}
                      alt={extra.name}
                      style={{
                        maxHeight: "2.5em",
                        marginRight: "5px",
                        verticalAlign: "middle",
                      }}
                    />
                  )}
                  {extra.name}
                </p>
              ) : (
                <ul>
                  <li>
                    {extra.image && (
                      <img
                        src={extra.image}
                        alt={extra.name}
                        style={{
                          maxHeight: "2.5em",
                          marginRight: "5px",
                          verticalAlign: "middle",
                        }}
                      />
                    )}
                    {extra.name}
                  </li>
                </ul>
              )}
            </div>
          ))}
          <p>
            We use fabric suppliers such as Camira, so the choices really are
            endless. Take a look at their range{" "}
            <a
              href="https://www.camirafabrics.com/en-uk"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
          <p>
            Please get in touch with{" "}
            <Link to={"/CustomerServices"} onClick={scrollToTop}>
              us
            </Link>{" "}
            today to discuss your requirements.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Product Dimensions</Accordion.Header>
        <Accordion.Body>
          {product.dimensions.map((dimension, index) =>
            dimension === dimension.toUpperCase() ? (
              <p key={index} style={{ fontWeight: "bold" }}>
                {dimension}
              </p>
            ) : (
              <ul key={index}>
                <li>{dimension}</li>
              </ul>
            )
          )}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Specification Sheet Download</Accordion.Header>
        <Accordion.Body>
          {product.pdfDownload ? (
            <>
              <p>
                View and download the product specification sheet as a pdf here.
              </p>
              <Button
                href={product.pdfDownload}
                target="_blank"
                className="spec-button"
              >
                View
              </Button>
            </>
          ) : (
            <p>Specification sheet not currently available for this product.</p>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ProductpageAccordian;
