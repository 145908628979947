import React from "react";
import Carousel from "react-bootstrap/Carousel";
import HomepageScrollButton from "./HomepageScrollButton";
import "../../styles.css";

const HomepageCarousel = () => {
  const images = [
    "/images/Homepageimgs/Iseofitout.jpg",
    "/images/Homepageimgs/Evolutionfitout.jpg",
    "/images/Homepageimgs/Piccolofitout.jpg",
    "/images/Homepageimgs/Unofitout.jpg",
    "/images/Homepageimgs/Arielfitout.jpg",
  ];

  const staticCaption = "ANOTHER DAY AT THE OFFICE";

  return (
    <Carousel className="homecarousel-size" fade controls= {false}>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            className="homeimg-size"
            src={process.env.PUBLIC_URL + image}
            alt={`Slide ${index + 1}`}
          />
          <Carousel.Caption className="center-imgtext">
            <h1>{staticCaption}</h1>
            <HomepageScrollButton />
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default HomepageCarousel;
