import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import aboutcontents from "./aboutcontents";
import "../../styles.css";

const AboutpageContents = () => {
  return (
    <div>
      {aboutcontents.map((aboutcontent, index) => (
        <Row key={index} className="aboutcontent-margin">
          {index % 2 === 0 ? (
            <>
              <Col
                className="order-1 px-0 d-flex align-items-center"
                xl={6}
                lg={12}
              >
                <Image
                  src={aboutcontent.aboutContentImg}
                  className="aboutcontent-img"
                  fluid
                />
              </Col>
              <Col
                className="aboutcontentbg-txt order-2 d-flex flex-column justify-content-center"
                xl={6}
                lg={12}
              >
                <h3>{aboutcontent.title}</h3>
                {aboutcontent.description.split("\n").map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </Col>
            </>
          ) : (
            <>
              <Col
                className="order-2 px-0 d-flex align-items-center"
                xl={6}
                lg={12}
              >
                <Image
                  src={aboutcontent.aboutContentImg}
                  className="aboutcontent-img"
                  fluid
                />
              </Col>
              <Col
                className="aboutcontentbg-txt order-1 d-flex flex-column justify-content-center"
                xl={6}
                lg={12}
              >
                <h3>{aboutcontent.title}</h3>
                {aboutcontent.description.split("\n").map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </Col>
            </>
          )}
        </Row>
      ))}
    </div>
  );
};

export default AboutpageContents;