import React, { useState } from "react";
import "../styles.css";

const Login = () => {
  const [isRegister, setIsRegister] = useState(false);

  const handleToggle = () => {
    setIsRegister(!isRegister);
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;
    // Handle login logic here
    console.log("Logging in with", username, password);
  };

  const handleRegister = (event) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;
    // Handle registration logic here
    console.log("Registering with", username, password);
  };

  return (
    <div className="page-content">
      <h2>{isRegister ? "Register" : "Login"} Page</h2>
      <form onSubmit={isRegister ? handleRegister : handleLogin}>
        <div>
          <label htmlFor="username">Username:</label>
          <input type="text" id="username" name="username" required />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input type="password" id="password" name="password" required />
        </div>
        <button type="submit">{isRegister ? "Register" : "Login"}</button>
      </form>
      <button onClick={handleToggle}>
        {isRegister ? "Go to Login" : "Go to Register"}
      </button>
    </div>
  );
};

export default Login;
