const products = [
  {
    name: "Iseo Range",
    slug: "iseo-range",
    shortDescription:
      "The perfect combination of Italian styling and British manufacturing finesse.",
    longDescription:
      "The Iseo is the biggest task chair in our range and it certainly lives up to its size. Iseo is equipped with a unique spinamic back supporting movement and bespoke mechanism. As well as this, you are provided with a vast range of standard features, including a choice of mesh or upholstered backs and black or white coloured frames and an optional headrest.\nThis chair will keep working hard for as long as you do.",
    features: [
      { name: "Height & slide adjustable soft pad 4D arms" },
      {
        name: "Fully synchronised mechanism with fingertip twist tension adjustment",
      },
      { name: "Ratchet height adjustable back with lumbar support" },
      { name: "Seat slide adjustment" },
      { name: "Back available as mesh or upholstered" },
      { name: "White or Black framework available" },
      { name: "5 Year warranty" },
      { name: "Spinamic back posture" },
      { name: "VE50/250 high density fire retardant foam" },
    ],
    extras: [
      { name: "Upholstered headrest with coat hanger" },
      { name: "Polished aluminum base" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 990-1100mm",
      "Headrest Height: +180-260mm",
      "Overall Width: 680-740mm (4D Arms)",
      "Overall Depth: 485mm",
      "Seat Width: 515mm",
      "Seat Depth: 480mm",
      "Seat Height: 435-560mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Iseo/IseoRange.png",
    slideImgs: [
      { src: "/images/Iseo/IseoMesh.png", caption: "Iseo White Frame Back" },
      { src: "/images/Iseo/IseoHr.png", caption: "Iseo Headrest" },
      { src: "/images/Iseo/IseoSideback.png", caption: "Iseo Headrest" },
      { src: "/images/Iseo/IseoBack.png", caption: "Iseo White Frame Back" },
      { src: "/images/Iseo/IseoUph.png", caption: "Iseo Uph Back" },
      { src: "/images/Iseo/IseoSide.png", caption: "Iseo Headrest" },
    ],
    situImg: "/images/Iseo/IseoSitu.jpg",
    pdfDownload: "/assets/SpecSheets/IseoSpecs.pdf",
  },
  {
    name: "Iseo Piccolo",
    slug: "iseo-piccolo-range",
    shortDescription:
      "Love the Iseo but need something a little smaller? The Piccolo is just that.",
    longDescription:
      "This medium sized task chair comes kitted out with features and holds an extremely competitive price point. Choose between the upholstered and Mesh options with your very own selection of fabric.",
    features: [
      { name: "3 Lever Heavy Duty Mechanism" },
      { name: "Seat Slide Adjustment" },
      { name: "Ratchet Back Adjustment" },
      { name: "Super Comfort Height Adjustable Arms" },
      { name: "Back available as mesh or upholstered" },
      { name: "5 Year warranty" },
    ],
    extras: [
      { name: "Synchro Mechanism" },
      { name: "Polished Aluminium Base" },
      { name: "Upholstered Headrest" },
      { name: "Draughtsman Set-Up" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],

    dimensions: [
      "Overall Height: 990-1120mm",
      "Headrest Height: +205mm",
      "Overall Width: 650mm",
      "Overall Depth: 565mm",
      "Seat Width: 485mm",
      "Seat Depth: 475mm",
      "Seat Height: 450-575mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/IseoPiccolo/PiccoloRange.png",
    slideImgs: [
      {
        src: "/images/IseoPiccolo/PiccoloMesh.png",
        caption: "Piccolo Mesh Back",
      },
      {
        src: "/images/IseoPiccolo/PiccoloUph.png",
        caption: "Piccolo Uph Back",
      },
    ],
    situImg: "/images/IseoPiccolo/PiccoloSitu.jpg",
    pdfDownload: "/assets/SpecSheets/PiccoloSpecs.pdf",
  },
  {
    name: "Tundra Range",
    slug: "tundra-range",
    shortDescription:
      "The visually stunning Tundra chair provides new levels of comfort and support.",
    longDescription:
      "The visually stunning Tundra chair provides new levels of comfort and support to suit the ergonomic needs of each individual user. The Synchro Plus mechanism with side tension adjustment and anti-kick safety feature provides the comfort needed for a long day at the office.",
    features: [
      { name: "Height adjustable arms" },
      { name: "Seat slide" },
      { name: "Synchro Plus mechanism" },
      { name: "Manual adjustment 2D lumbar support" },
      { name: "VE50/250 high-density fire retardant foam" },
      { name: "Five-year warranty" },
      { name: "Height and depth adjustable lumbar support" },
    ],
    extras: [
      { name: "Black mesh headrest" },
      { name: "Polished aluminum base" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 1060-1180mm",
      "Optional Headrest: +340mm ",
      "Overall Width: 600mm",
      "Overall Depth: 700mm",
      "Seat Width: 500mm",
      "Seat Depth: 460mm",
      "Seat Height: 440-560mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Tundra/TundraRange.png",
    slideImgs: [
      { src: "/images/Tundra/TundraMeshBlue.png", caption: "Tundra Mesh Back" },
      {
        src: "/images/Tundra/TundraMeshYellow.png",
        caption: "Tundra Mesh Back",
      },
      { src: "/images/Tundra/TundraUph.png", caption: "Tundra Uph Back" },
      { src: "/images/Tundra/TundraUphSide.png", caption: "Tundra Uph Back" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/TundraSpecs.pdf",
  },
  {
    name: "Evolution Range",
    slug: "evolution-range",
    shortDescription:
      "This brand new, stunning task chair is the Evolution to our classic Tundra chair. ",
    longDescription:
      "With a new deign, and updated features, the Evolution will keep you comfortable and supported all day long. ",
    features: [
      { name: "Full Synchro Mechanism" },
      { name: "Adjustable Lumbar Support Bar" },
      { name: "Height Adjustable 1D Arms" },
      { name: "Five-year warranty" },
    ],
    extras: [
      { name: "Black Mesh Headrest" },
      { name: "Polished Aluminium Base" },
      { name: "Seat Slide" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 1060-1180mm",
      "Optional Headrest: +340mm ",
      "Overall Width: 600mm",
      "Overall Depth: 700mm",
      "Seat Width: 500mm",
      "Seat Depth: 460mm",
      "Seat Height: 440-560mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Evolution/EvolutionRange.png",
    slideImgs: [
      { src: "/images/Evolution/EvolutionMesh.png", caption: "Evo Mesh Back" },
      {
        src: "/images/Evolution/EvolutionMeshBack.png",
        caption: "Evo Mesh Back",
      },
      {
        src: "/images/Evolution/EvolutionMeshGreen.png",
        caption: "Evo Mesh Back",
      },
      {
        src: "/images/Evolution/EvolutionMeshSide.png",
        caption: "Evo Mesh Back",
      },
    ],
    situImg: "/images/Evolution/EvolutionSitu.jpg",
    pdfDownload: "/assets/SpecSheets/EvolutionSpecs.pdf",
  },
  {
    name: "Delta Range",
    slug: "delta-range",
    shortDescription:
      "The Delta range boasts high levels of comfort and features across all 4 models, making it the go-to range for a busy office. ",
    longDescription:
      "With seat slide adjustment and height adjustable arms as standard, this chair certainly punches well above its price tag." +
      "\nThe Delta is also a brilliant home working option - available with door-to-door delivery.",
    features: [
      { name: "Height adjustable arms" },
      { name: "Seat slide" },
      { name: "Gas lift for height adjustment" },
      { name: "Height adjustable lumbar support" },
      { name: "VE50/250 high-density fire retardant foam" },
      { name: "Five-year warranty" },
      { name: "Tension adjustable synchronised mechanism" },
    ],
    extras: [
      { name: "Upholstered headrest" },
      { name: "Polished aluminum base" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      { name: "Draughtsman setup" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 1060-1180mm",
      "Optional Headrest: +340mm",
      "Overall Width: 580mm",
      "Overall Depth: 700mm",
      "Seat Width: 460mm",
      "Seat Depth: 455mm",
      "Seat Height: 440-560mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Delta/DeltaRange.png",
    slideImgs: [
      { src: "/images/Delta/DeltaMeshBlue.png", caption: "Delta Mesh Back" },
      { src: "/images/Delta/DeltaMeshGrey.png", caption: "Delta Mesh Back" },
      { src: "/images/Delta/DeltaMeshLGrey.png", caption: "Delta Mesh Back" },
      { src: "/images/Delta/DeltaMeshBack.png", caption: "Delta Mesh Back" },
      { src: "/images/Delta/DeltaRibbed.png", caption: "Delta Ribbed Back" },
      {
        src: "/images/Delta/DeltaRibbedBack.png",
        caption: "Delta Ribbed Back",
      },
      { src: "/images/Delta/DeltaLux.png", caption: "Delta Lux Back" },
      { src: "/images/Delta/DeltaAndros.png", caption: "Delta Andros Back" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/DeltaSpecs.pdf",
  },
  {
    name: "Haff 150 Range",
    slug: "haff-range",
    shortDescription:
      "Take the weight off your feet and appreciate the comfort this chair provides.",
    longDescription:
      "The ergonomically sculptured back and supportive seat foam ensures ultimate comfort. This Haff 150 range is truly an all-day, everyday chair with a heavy-duty independent 3 lever multi-adjustment mechanism." +
      "The integral seat slide gives you even more adjustability." +
      "\nChoose between a range of designs, including the new Haff Box, to suit your individual needs.",
    features: [
      { name: "Foldaway, height adjustable arms" },
      {
        name: "Heavy duty independent 3 lever multi adjustment mechanism with tension control",
      },
      { name: "Seat slide" },
      { name: "Ratchet back height adjustment" },
      { name: "VE50/250 high-density fire retardant foam" },
      { name: "Five-year warranty" },
      { name: "Heavy-duty 24hr chair" },
      { name: "Inflatable lumbar support" },
      {
        name: "Don't need so many specs? We can also offer the BOXX, BELL, BULB and MESH options with a smaller seat, lighter mechanism, and fixed arms at a lower cost. Please see Baseline Range for details.",
      },
    ],
    extras: [
      { name: "Fixed Height Adjustable Arms" },
      { name: "24/7 Extra HD Mechanism - UWC 180Kgs" },
      { name: "Polished aluminum base" },
      { name: "Smaller seat option" },
      { name: "Draughtsman Set-Up" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      { name: "Brake loaded castors" },
      { name: "Multi-Pivot Headrest - Box / Bell / Bulb" },
      { name: "2-tone fabric FOC" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 1060-1180mm",
      "Overall Width: 600mm",
      "Overall Depth: 740mm",
      "Seat Width: 460mm",
      "Seat Depth: 455mm",
      "Seat Height: 440-560mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Haff/HaffRange.png",
    slideImgs: [
      { src: "/images/Haff/HaffBell.png", caption: "Haff Bell" },
      { src: "/images/Haff/HaffBox.png", caption: "Haff Box" },
      { src: "/images/Haff/HaffBulb.png", caption: "Haff Bulb" },
      { src: "/images/Haff/HaffMesh.png", caption: "Haff Mesh" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/HaffSpecs.pdf",
  },
  {
    name: "Baseline Range",
    slug: "baseline-range",
    shortDescription:
      "All the comfort and style of the HAFF at a BASELINE price.",
    longDescription:
      "The Baseline group is our best value and feature-packed office chair range. The collection is designed for maximum comfort and support. ",
    features: [
      { name: "Gas lift for seat height adjustment" },
      { name: "Height adjustable back" },
      { name: "Height adjustable arms" },
      { name: "High-density fire retardant foam" },
      { name: "Three-year warranty" },
      { name: "Adjustable arms" },
      { name: "Inflatable lumbar" },
      {
        name: "3-lever 'user plus' mechanism with back rake, seat tilt and height adjustment",
      },
    ],
    extras: [
      { name: "Heavy duty mechanism" },
      { name: "Polished aluminum base" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      { name: "Draughtsman setup" },
      { name: "Seat Slide" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 1070-1200mm",
      "Overall Width: 600mm",
      "Overall Depth: 650mm",
      "Seat Width: 520mm",
      "Seat Depth: 520mm",
      "Seat Height: 440-570mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Baseline/BaselineRange.png",
    slideImgs: [
      { src: "/images/Baseline/BaselineBell.png", caption: "Baseline Bell" },
      { src: "/images/Baseline/BaselineBox.png", caption: "Baseline Box" },
      { src: "/images/Baseline/BaselineBulb.png", caption: "Baseline Bulb" },
      { src: "/images/Baseline/BaselineMesh.png", caption: "Baseline Mesh" },
    ],
    situImg: "/images/Baseline/BaselineSitu.jpg",
    pdfDownload: "/assets/SpecSheets/BaselineSpecs.pdf",
  },
  {
    name: "Ergo Range",
    slug: "ergo-range",
    shortDescription:
      "When it comes to back care, the Haff Ergo has you covered!",
    longDescription:
      "As our signature ergonomic chair, the sculptured back and supportive seat foam are designed to maintain upright posture, ensure appropriate blood flow and improve circulation." +
      "\nThe Ergo is even available with a range of different back heights to match your body's specific needs. The Ergo can maintain continuous and bespoke back support. It is perfect as both a long use operator chair and a preventative back care solution." +
      "\nERGO 680 - Mid Back." +
      "\nERGO 550 - Low Back." +
      "\nERGO 750 - High Back." +
      "\nDelta Ergo - Mid Back Ergo with Delta Seat.",
    features: [
      { name: "Foldaway, height adjustable arms" },
      {
        name: "Heavy duty independent 3 lever multi-adjustment mechanism with tension control & seat slide",
      },
      { name: "Ratchet back height adjustment" },
      { name: "VE50/250 high density fire retardant foam" },
      { name: "Five year warranty" },
    ],
    extras: [
      { name: "Inflatable lumbar support" },
      { name: "Polished aluminium base" },
      { name: "Smaller seat option" },
      { name: "Hard-floor castors" },
      { name: "Glides" },
      { name: "Headrest" },
      { name: "2-tone fabric FOC" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "High Back Overall Height: 1160-1280mm",
      "Mid Back Overall Height: 1060-1180mm",
      "Low Back Overall Height: 1030-1150mm",
      "Headrest Height: +170mm",
      "Overall Width: 600mm",
      "Overall Depth: 740mm",
      "Seat Width: 460mm",
      "Seat Depth: 455mm",
      "Seat Height: 440-560mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Ergo/ErgoRange.png",
    slideImgs: [
      { src: "/images/Ergo/ErgoHighBack.png", caption: "Ergo High Back" },
      { src: "/images/Ergo/ErgoMidBack.png", caption: "Ergo Mid Back" },
      { src: "/images/Ergo/ErgoLowBack1.png", caption: "Ergo Low Back" },
      { src: "/images/Ergo/ErgoLowBack2.png", caption: "Ergo Low Back" },
      { src: "/images/Ergo/ErgoLowBack3.png", caption: "Ergo Low Back" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/ErgoSpecs.pdf",
  },
  {
    name: "The Nuark",
    slug: "nuark-range",
    shortDescription:
      "The NUARK offers a contemporary design with a wealth of ergonomic and comfort enhancing features.",
    longDescription:
      "The NUARK offers a contemporary design with a wealth of ergonomic and comfort enhancing features, including its height-adjustable lumbar support and body balance mechanism, perfect for all working environments.",
    features: [
      { name: "Gas lift for seat height adjustment" },
      { name: "Height adjustable back & lumbar support" },
      {
        name: "Body Balance Synchro Mechanism, self  adjusts to suit the user's bodyweight",
      },
      { name: "High-density fire retardant foam" },
      { name: "Three-year warranty" },
      { name: "Seat slide" },
    ],
    extras: [
      { name: "Polished aluminum base" },
      { name: "Hard floor castors" },
      { name: "Glides" },
      { name: "Upholstered Headrest" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 1070-1200mm",
      "Overall Width: 600mm",
      "Overall Depth: 650mm",
      "Seat Width: 520mm",
      "Seat Depth: 520mm",
      "Seat Height: 440-570mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Nuark/NuarkRange.png",
    slideImgs: [
      { src: "/images/Nuark/NuarkMesh.png", caption: "Nuark Mesh" },
      { src: "/images/Nuark/NuarkBack.png", caption: "Nuark Mesh" },
      { src: "/images/Nuark/NuarkSide.png", caption: "Nuark Mesh" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/NuarkSpecs.pdf",
  },
  {
    name: "Uno Range",
    slug: "uno-range",
    shortDescription: "The Uno is the definition of Utility Seating.",
    longDescription:
      "The Uno is smart, super-comfortable, and completely adaptable to your needs. This chair will work in any space. With a tension strung mesh back to keep you comfortable all day long, simply choose between a Five Star or Cantilever base. Add a fabric to match.",
    features: [
      { name: "Fixed arms" },
      { name: "Tension Strung Mesh Back" },
      { name: "Three year warranty" },
      { name: "High density fire retardant moulded foam" },
      { name: "Black moulded frame" },
    ],
    extras: [
      { name: "White Shell and Base" },
      { name: "Glides" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 920-1000mm",
      "Overall Width: 605mm",
      "Overall Depth: 470mm",
      "Seat Width: 460mm",
      "Seat Depth: 370mm",
      "Seat Height: 480-560mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Uno/UnoRange.png",
    slideImgs: [
      { src: "/images/Uno/UnoCanti.png", caption: "Uno Cantilever" },
      { src: "/images/Uno/UnoFourleg.png", caption: "Uno Pyramid" },
      { src: "/images/Uno/UnoPyramid.png", caption: "Uno Pyramid" },
      { src: "/images/Uno/UnoSwivel.png", caption: "Uno Swivel" },
    ],
    situImg: "/images/Uno/UnoSitu.jpg",
    pdfDownload: "/assets/SpecSheets/UnoSpecs.pdf",
  },
  {
    name: "Matrix Range",
    slug: "matrix-range",
    shortDescription: "The ultimate cantilever utility chair",
    longDescription:
      "Available in both chrome and black frames, this chair will slide seamlessly into your workplace.",
    features: [
      { name: "Fixed arms" },
      { name: "Available in mid back, high back and mesh back options" },
      { name: "Three year warranty" },
      { name: "High density fire retardant moulded foam" },
    ],
    extras: [
      { name: "Chrome or back frame options available" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 920-1000mm",
      "Overall Width: 605mm",
      "Overall Depth: 470mm",
      "Seat Width: 460mm",
      "Seat Depth: 370mm",
      "Seat Height: 480-560mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Matrix/MatrixRange.png",
    slideImgs: [
      { src: "/images/Matrix/MatrixLBack.png", caption: "Matrix High Back" },
      { src: "/images/Matrix/MatrixMesh.png", caption: "Matrix Mesh Back" },
      { src: "/images/Matrix/MatrixSBack.png", caption: "Matrix Mid Back" },
      { src: "/images/Matrix/MatrixSide.png", caption: "Matrix Mid Back Side" },
      { src: "/images/Matrix/MatrixHBack.png", caption: "Matrix High Back" },
    ],
    situImg: "",
    pdfDownload: "",
  },
  {
    name: "Scoop Range",
    slug: "scoop-range",
    shortDescription:
      "The Scoop is the perfect balance of style and comfort and a great addition to anyone's office space.",
    longDescription:
      "Available with four different base options, this chair can be made to look great in whatever setting you require it for.",
    features: [
      { name: "Fixed arms as standard" },
      {
        name: "Choose between 5 Star base with castor, Pyramid base on glides, Cantilever, or 4 legged base frame options.",
      },
      { name: "Swivel chair mechanism has tilt-lock rocker action" },
      { name: "Cantilever base and four-leg base options are fully stackable" },
      { name: "Five year warranty" },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
      { name: "SHELL COLOURS" },
      { name: "Moss" },
      { name: "Light Grey" },
      { name: "Snowball" },
      { name: "Dark" },
      { name: "FRAMEWORK FINISHES" },
      { name: "Chrome" },
      { name: "Matt Black" },
    ],
    dimensions: [
      "Overall Height: 835-915mm",
      "Overall Width: 640mm",
      "Overall Depth: 640mm",
      "Seat Width: 440mm",
      "Seat Depth: 445mm",
      "Seat Height: 460-540mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Scoop/ScoopRange.png",
    slideImgs: [
      { src: "/images/Scoop/ScoopCanti.png", caption: "Scoop Cantilever" },
      { src: "/images/Scoop/ScoopLegs.png", caption: "Scoop Legs" },
      { src: "/images/Scoop/ScoopPyramid.png", caption: "Scoop Pyramid" },
      { src: "/images/Scoop/ScoopSwivel.png", caption: "Scoop Swivel" },
      { src: "/images/Scoop/ScoopSide.png", caption: "Scoop Swivel" },
    ],
    situImg: "/images/Scoop/ScoopSitu.jpg",
    pdfDownload: "/assets/SpecSheets/ScoopSpecs.pdf",
  },
  {
    name: "Bella Range",
    slug: "bella-range",
    shortDescription:
      "A simple yet contemporary Italian design capturing beauty, elegance, and purpose.",
    longDescription:
      "The Bella is contoured to fit the body perfectly and provide as much support as you need for the rigours of a working day, be it home or office. Height adjustable lumbar support comes as standard, providing additional tailored support.",
    features: [
      { name: "Fixed arms" },
      { name: "Mesh or Upholstered back options" },
      { name: "Three year warranty" },
      { name: "Height adjustable lumbar support" },
      { name: "High density fire retardant moulded foam" },
      { name: "Chrome cantilever frames" },
      { name: "Black shell" },
    ],
    extras: [
      { name: "Polished aluminum ARC arms" },
      { name: "White shell option" },
      { name: "Upholstered back option" },
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: [
      "Overall Height: 800mm",
      "Overall Width: 505mm",
      "Width with Arms: 520mm",
      "Overall Depth: 550mm",
      "Seat Width: 480mm",
      "Seat Depth: 550mm",
      "Seat Height: 465mm",
      "Weight Capacity: 150kgs",
    ],
    rangeImg: "/images/Bella/BellaRange.png",
    slideImgs: [
      { src: "/images/Bella/BellaCanti.png", caption: "Bella Cantilever" },
      { src: "/images/Bella/BellaSwiv.png", caption: "Bella Swivel" },
      { src: "/images/Bella/BellaSide.png", caption: "Bella Swivel" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/BellaSpecs.pdf",
  },
  {
    name: "The Perch",
    slug: "perch-range",
    shortDescription:
      "A different option to the more traditional seating choices, aiming to inspire pelvic movement and stimulate hip and core movement.",
    longDescription:
      "Specially designed with Sit-Stand desking in mind, the Perch allows you the ability to increase the height of the stool from 530mm to a stable 760mm. An easy-to-use gas height adjustment allowing you to choose the correct height that you prefer to work at in a matter of seconds.",
    features: [
      { name: "Available in red, white or black" },
      {
        name: "Upholstered seat pad is available in a large range of fabric or vinyl options",
      },
      { name: "5 year warranty" },
      {
        name: "Arrives complete in a sturdy, easy to post box which can be delivered straight to your client's door",
      },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
      { name: "FRAMEWORK FINISHES" },
      { name: "Black" },
      { name: "White" },
      { name: "Red" },
    ],
    dimensions: [
      "Overall Height: 530-760mm",
      "Overall Width: 435mm",
      "Overall Depth: 240mm",
      "Seat Width: 360mm",
      "Seat Depth: 235mm",
      "Seat Height: 530-760mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Perch/PerchRange.png",
    slideImgs: [
      { src: "/images/Perch/PerchBlack.png", caption: "Perch Black" },
      { src: "/images/Perch/PerchWhite.png", caption: "Perch White" },
      { src: "/images/Perch/PerchRed.png", caption: "Perch Red" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/PerchSpecs.pdf",
  },
  {
    name: "Syd Range",
    slug: "syd-range",
    shortDescription:
      "Comfort meets style in the SYD range. Its minimal Nordic design will look great in any setting.",
    longDescription:
      "The Syd's minimal Nordic design will look great in any setting, whilst its ergonomic structure will keep you sitting comfortably. The SYD's ash wood shell is available in four different finishes. For its four legged base, choose between multi-layered ash-wood or the chrome and black painted steel versions. For extra comfort, simply add an upholstered seat pad.",
    features: [
      {
        name: "Lead times may vary on these products. Please enquire for more details.",
      },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available for the optional seat cushion.",
      },
      { name: "ASH WOOD SHELL FINISHES" },
      { name: "Bleached" },
      { name: "Oak" },
      { name: "Walnut" },
      { name: "Black" },
      { name: "METAL FRAME FINISHES" },
      { name: "Chrome" },
      { name: "Matt Black" },
    ],
    dimensions: [
      "Overall Height: 815mm",
      "Overall Width: 520mm",
      "Seat Width: 430mm",
      "Seat Depth: 400mm",
      "Seat Height: 475mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Syd/SydRange.png",
    slideImgs: [
      { src: "/images/Syd/SydWood.png", caption: "Syd Wood" },
      { src: "/images/Syd/SydMetal.png", caption: "Syd Metal" },
    ],
    situImg: "/images/Syd/SydSitu.png",
    pdfDownload: "",
  },
  {
    name: "Tub Range",
    slug: "tub-range",
    shortDescription: "The soft seating area staple.",
    longDescription:
      "The perfect centrepiece to your soft seating area, plus you can add a headrest for even more support. Try the swivel seat option for a more versatile chair which can even be used at a desk. As with all our products, we are able to source variations to suit your specific needs. Should you require something a little different, then feel free to ask!",
    features: [
      { name: "Please expect a 4 week delivery time for these products" },
      { name: "Measurements may be subject to minimal variations" },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
      { name: "SWIVEL SEAT" },
      { name: "Gas height adjustment" },
      { name: "Tension Control Tilt Lockable Mechanism" },
      { name: "Tilt Rock Mechanism" },
    ],
    dimensions: [
      "Overall Height: 780mm",
      "With Headrest: 1100mm",
      "Overall Width: 745mm",
      "Overall Depth: 630mm",
      "Seat Width: 515mm",
      "Seat Depth: 530mm",
      "Seat Height: 430mm",
      "With Swivel Base: 450-540mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Tub/TubRange.png",
    slideImgs: [
      { src: "/images/Tub/TubSkid.png", caption: "Tub Skid Frame" },
      { src: "/images/Tub/TubHBack.png", caption: "Tub High Back" },
      { src: "/images/Tub/TubSkid2.png", caption: "Tub Skid Frame" },
      { src: "/images/Tub/TubSwivel.png", caption: "Tub Swivel" },
    ],
    situImg: "/images/Tub/TubSitu.jpg",
    pdfDownload: "/assets/SpecSheets/TubSpecs.pdf",
  },
  {
    name: "Lynx Range",
    slug: "lynx-range",
    shortDescription: "The most versatile chair in our soft-seating range.",
    longDescription:
      "The Lynx is perfect for your break-out space, canteen, student accommodation, or even a modern alternative to a desk chair. It's pretty comfortable too!",
    features: [
      {
        name: "Customise to your own specs and choose between the 5 star base, pyramid base, high stool or wooden frame",
      },
      { name: "Rocking Tilt mechanism (with 5 star and Pyramid bases)" },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: ["Subject to chair design"],
    rangeImg: "/images/Lynx/LynxRange.png",
    slideImgs: [
      { src: "/images/Lynx/LynxGreen.png", caption: "Lynx Pyramid" },
      { src: "/images/Lynx/LynxGrey.png", caption: "Lynx Pyramid" },
      { src: "/images/Lynx/LynxWooden.png", caption: "Lynx Wooden Legs" },
    ],
    situImg: "/images/Lynx/LynxSitu.jpg",
    pdfDownload: "",
  },
  {
    name: "Kooche Range",
    slug: "kooche-range",
    shortDescription:
      "With it's sleek lines and supreme comfort, Kooche is as good to sit on as it is to look at.",
    longDescription:
      "Designed with both comfort and functionality in mind, the Kooche range offers an elegant solution to soft seating that combines regal style, excellent ergonomics, and practicality all helping to create a more productive and effective working environment for you and your team.",
    features: [
      {
        name: "Fully customisable design. Use our specification sheet download for full details",
      },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available",
      },
    ],
    dimensions: ["Subject to booth design"],
    rangeImg: "/images/Kooche/KoocheRange.png",
    slideImgs: [
      { src: "/images/Kooche/Kooche.png", caption: "Kooche Booth" },
      {
        src: "/images/Kooche/KoocheCatalogue.png",
        caption: "Kooche Catalogue",
      },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/KoocheSpecs.pdf",
  },
  {
    name: "Sit-Stack Range",
    slug: "sit-stack-range",
    shortDescription:
      "Made from recycled polypropylene and weighing only 7kg, this chair can be linked and stacked with ease.",
    longDescription:
      "The Sit-Stack chair is comfortable, tough, easy to handle and link; making it ideal for conferences, schools, and universities, dining areas, common rooms, and offices. In addition to this, the sit-stack also has the optional feature of a trolley.",
    features: [
      { name: "Stacks 40 high in 2 meters" },
      { name: "High tensile 12mm steel wire sled frame coated with chrome" },
      {
        name: "Contoured seat moulded from recycled  polypropylene with a matte finish",
      },
      { name: "Clear polycarbonate linking feet and stacking blocks" },
      { name: "5 Year warranty" },
      { name: "Linking Bracket - included with all skid chairs" },
    ],
    extras: [
      { name: "Steel Frame available in chrome finish" },
      { name: "Trolley, which stacks 32 high" },
      { name: "Writing tablet (side chair only)" },
      { name: "SHELL COLOURS" },
      { name: "White", image: "/assets/ProductColours/SitStack/White.png" },
      { name: "Green", image: "/assets/ProductColours/SitStack/Green.png" },
      { name: "Blue", image: "/assets/ProductColours/SitStack/Blue.png" },
      {
        name: "Soft Red",
        image: "/assets/ProductColours/SitStack/SoftRed.png",
      },
      { name: "Black", image: "/assets/ProductColours/SitStack/Black.png" },
      { name: "Light Grey", image: "/assets/ProductColours/SitStack/Grey.png" },
      { name: "Bespoke shell colours available" },
    ],
    dimensions: [
      "Overall Height: 840mm",
      "Overall Width: 490mm",
      "Overall Depth: 525mm",
      "Seat Width: 480mm",
      "Seat Depth: 460mm",
      "Seat Height: 450mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Sit-Stack/SitStackRange.png",
    slideImgs: [
      { src: "/images/Sit-Stack/SitStack.png", caption: "Sit-Stack Single" },
      { src: "/images/Sit-Stack/SitStacks.png", caption: "Sit-Stacks" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/SitStackSpecs.pdf",
  },
  {
    name: "Duke Range",
    slug: "duke-range",
    shortDescription: "Sit back, relax and enjoy the sun.",
    longDescription:
      "The UV resistant Duke is the perfect option for your outdoor seating needs. It's smart, colourful, and incredibly comfortable. This chair won a Red-dot award for its amazing design.",
    features: [
      {
        name: "Available in 100% recycled Rinasco and fully stackable. The Duke is made from a single mould frame. Choose to add an upholstered seat pad and armrests for extra comfort.",
      },
      {
        name: "Please note, this range may come with a lead time of up to 4 weeks from order. Please enquire for more details.",
      },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available for the optional seat cushion.",
      },
      { name: "RINASCO 100% RECYCLED" },
      { name: "Blue", image: "/assets/ProductColours/Duke/BlueRecycled.png" },
      { name: "Grey", image: "/assets/ProductColours/Duke/GreyRecycled.png" },
      { name: "Dark", image: "/assets/ProductColours/Duke/BlackRecycled.png" },
      { name: "SHELL COLOURS" },
      { name: "Ice", image: "/assets/ProductColours/Duke/Ice.png" },
      { name: "Swamp", image: "/assets/ProductColours/Duke/Swamp.png" },
      { name: "Powder", image: "/assets/ProductColours/Duke/Powder.png" },
      { name: "Snowball", image: "/assets/ProductColours/Duke/Snowball.png" },
      { name: "Sand", image: "/assets/ProductColours/Duke/Sandy.png" },
      { name: "Rose", image: "/assets/ProductColours/Duke/Coral.png" },
      { name: "Sun", image: "/assets/ProductColours/Duke/Sun.png" },
    ],
    dimensions: [
      "NO ARMS",
      "Overall Height: 820mm",
      "Overall Width: 560mm",
      "Overall Depth: 530mm",
      "Seat Width: 455mm",
      "Seat Depth: 415mm",
      "Seat Height: 460mm",
      "Weight Capacity: 120kgs",
      "ARMS",
      "Overall Height: 820mm",
      "Overall Width: 630mm",
      "Overall Depth: 530mm",
      "Seat Width: 455mm",
      "Seat Depth: 415mm",
      "Seat Height: 460mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Duke/DukeRange.png",
    slideImgs: [
      { src: "/images/Duke/Duke.png", caption: "Duke No Arms" },
      { src: "/images/Duke/DukeArm.png", caption: "Duke Arms" },
      { src: "/images/Duke/DukeHighStool.png", caption: "Duke High Stool" },
      { src: "/images/Duke/DukeLowStool.png", caption: "Duke Low Stool" },
    ],
    situImg: "/images/Duke/DukeSitu.jpg",
    pdfDownload: "/assets/SpecSheets/DukeSpecs.pdf",
  },
  {
    name: "Vango Range",
    slug: "vango-range",
    shortDescription: "One of our smartest and versatile utility chairs.",
    longDescription:
      "The Vango is the perfect compliment to any space. We've seen it in meeting rooms, break-out spaces, conference rooms, canteens and even at work desks. The Vango's sleek metal frame is available in stackable four legs and slides; with the additional options of a 4 star base, or even a bench. For some extra comfort, try adding the upholstered seat pad.",
    features: [
      {
        name: "Choose your style - Four Legs, Slides, Star-Base and Bench-Seat",
      },
      { name: "SHELL & FRAME COLOURS" },
      { name: "Swamp", image: "/assets/ProductColours/Vango/Swamp.png" },
      { name: "Powder", image: "/assets/ProductColours/Vango/Powder.png" },
      { name: "Snowball", image: "/assets/ProductColours/Vango/Snowball.png" },
      { name: "Road", image: "/assets/ProductColours/Vango/Road.png" },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available for the optional seat cushion.",
      },
      {
        name: "The Bench style can be made upto five seats wide. Please enquire for measurements",
      },
    ],
    dimensions: [
      "FOUR LEGS",
      "Overall Height: 785mm",
      "Overall Width: 565mm",
      "Overall Depth: 555mm",
      "Seat Width: 440mm",
      "Seat Depth: 430mm",
      "Seat Height: 450mm",
      "Weight Capacity: 120kgs",
      "SLIDES",
      "Overall Height: 785mm",
      "Overall Width: 565mm",
      "Overall Depth: 550mm",
      "Seat Width: 440mm",
      "Seat Depth: 430mm",
      "Seat Height: 450mm",
      "Weight Capacity: 120kgs",
      "FIVE STAR",
      "Overall Height: 730-855mm",
      "Overall Width: 640mm (base)",
      "Overall Depth: 535mm",
      "Seat Width: 440mm",
      "Seat Depth: 430mm",
      "Seat Height: 385-510mm",
      "Weight Capacity: 120kgs",
      "HIGH STOOL",
      "Overall Height: 1110mm",
      "Overall Width: 565mm (base)",
      "Overall Depth: 600mm",
      "Seat Width: 440mm",
      "Seat Depth: 430mm",
      "Seat Height: 730mm",
      "Foot Rest Height: 290mm",
      "Weight Capacity: 120kgs",
      "BENCH",
      "Overall Height: 775mm",
      "Overall Width: 1525 (for 2 seater)",
      "Overall Depth: 610mm",
      "Seat Width: 440mm",
      "Seat Depth: 440mm",
      "Seat Height: 420mm",
      "*Can be made upto five seats wide.",
    ],
    rangeImg: "/images/Vango/VangoRange.png",
    slideImgs: [
      { src: "/images/Vango/Vango4Leg.png", caption: "Vango Legs" },
      { src: "/images/Vango/VangoBench.png", caption: "Vango Bench" },
      { src: "/images/Vango/VangoHigh.png", caption: "Vango High Stool" },
      { src: "/images/Vango/VangoSlides.png", caption: "Vango Slides" },
      { src: "/images/Vango/VangoSwivel.png", caption: "Vango Swivel" },
    ],
    situImg: "/images/Vango/VangoSitu.jpg",
    pdfDownload: "/assets/SpecSheets/VangoSpecs.pdf",
  },
  {
    name: "Kora Range",
    slug: "kora-range",
    shortDescription:
      "This beautiful chair is the perfect combination of style and functionality.",
    longDescription:
      "Manufactured from a single, bleach-able, wipe clean one mould frame, the Kora is available as a side chair or an armchair with a seat pad option for both models.",
    features: [
      {
        name: "Please note, this range may come with a lead time of up to 4 weeks from order. Please enquire for more details.",
      },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available for the optional seat cushion.",
      },
      { name: "SHELL COLOURS" },
      { name: "Moss", image: "/assets/ProductColours/Kora/Moss.png" },
      { name: "Swamp", image: "/assets/ProductColours/Kora/Swamp.png" },
      { name: "Powder", image: "/assets/ProductColours/Kora/Powder.png" },
      { name: "Snowball", image: "/assets/ProductColours/Kora/Snowball.png" },
      { name: "Road", image: "/assets/ProductColours/Kora/Road.png" },
    ],
    dimensions: [
      "Overall Height: 800mm",
      "Overall Width: 505mm",
      "Width with Arms: 520mm",
      "Overall Depth: 550mm",
      "Seat Width: 480mm",
      "Seat Depth: 400mm",
      "Seat Height: 465mm",
      "Weight Capacity: 120kgs",
    ],
    rangeImg: "/images/Kora/KoraRange.png",
    slideImgs: [
      { src: "/images/Kora/KoraLegs.png", caption: "Kora Legs" },
      { src: "/images/Kora/KoraArm.png", caption: "Kora Arms" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/KoraSpecs.pdf",
  },
  {
    name: "Ariel Range",
    slug: "ariel-range",
    shortDescription:
      "This wonderful chair is the perfect combination of style and functionality.",
    longDescription:
      "Manufactured from a single, bleach-able, wipe clean one mould frame, the Ariel is available with an upholstered seat panel and a selection of different bases." +
      "\nFour Legs, Pyramid or Star Base Options." +
      "\nSimply choose your favourite!",
    features: [
      { name: "SHELL COLOURS" },
      { name: "Moss", image: "/assets/ProductColours/Ariel/Moss.png" },
      { name: "Swamp", image: "/assets/ProductColours/Ariel/Swamp.png" },
      { name: "Powder", image: "/assets/ProductColours/Ariel/Powder.png" },
      { name: "Snowball", image: "/assets/ProductColours/Ariel/Snowball.png" },
      { name: "Dark", image: "/assets/ProductColours/Ariel/Dark.png" },
      { name: "Cream", image: "/assets/ProductColours/Ariel/Cream.png" },
    ],
    extras: [
      {
        name: "A wide range of fabric, vinyl & leather finishes are available for the optional seat cushion.",
      },
      { name: "FOUR LEG FRAME FINISHES" },
      { name: "Black Base" },
      { name: "Wood Fibre" },
      { name: "Wood" },
      { name: "RINASCO 100% RECYCLED" },
      { name: "Dark", image: "/assets/ProductColours/Ariel/DarkRecycled.png" },
      { name: "Grey", image: "/assets/ProductColours/Ariel/GreyRecycled.png" },
      {
        name: "Orange",
        image: "/assets/ProductColours/Ariel/GingerRecycled.png",
      },
      { name: "PYRAMID & STAR FRAME FINISHES" },
      { name: "Aluminium" },
      { name: "White Nylon" },
      { name: "Black Nylon" },
    ],
    dimensions: [
      "FOUR LEGS",
      "Overall Height: 770mm",
      "Overall Width: 570mm",
      "Overall Depth: 545mm",
      "Seat Width: 450mm",
      "Seat Depth: 440mm",
      "Seat Height: 485mm",
      "Weight Capacity: 120kgs",
      "PYRAMID",
      "Overall Height: 750-755mm",
      "Overall Width: 580mm",
      "Overall Depth: 550mm",
      "Seat Width: 450mm",
      "Seat Depth: 435mm",
      "Seat Height: 175mm",
      "Leg Width: 640mm",
      "Leg Depth: 515mm",
      "STAR",
      "5 Star Height: 755--835mm",
      "4 Star Height: 750--830mm",
      "Overall Width: 580mm",
      "Overall Depth: 550mm",
      "Seat Width: 450mm",
      "Seat Depth: 435mm",
      "Seat Height: 175mm",
      "Base Height: 460-540mm",
      "Leg Width: 640mm",
      "Leg Depth: 530mm",
    ],
    rangeImg: "/images/Ariel/ArielRange.png",
    slideImgs: [
      { src: "/images/Ariel/Ariel4Legs.png", caption: "Ariel Legs" },
      { src: "/images/Ariel/Ariel4Star.png", caption: "Ariel 4 Star" },
      { src: "/images/Ariel/Ariel5Star.png", caption: "Ariel 5 Star" },
      { src: "/images/Ariel/ArielRecycled.png", caption: "Ariel Legs" },
      { src: "/images/Ariel/ArielWooden.png", caption: "Ariel Wooden Legs" },
      {
        src: "/images/Ariel/ArielWoodFibre.png",
        caption: "Ariel Wood Fibre Legs",
      },
    ],
    situImg: "/images/Ariel/ArielSitu.jpg",
    pdfDownload: "",
  },
  {
    name: "Trästol Range",
    slug: "trastol-range",
    shortDescription:
      "Our signature wooden frame collection.",
    longDescription:
      "The Trästol's clean lines and smooth finish make for a uniquely Scandinavian look. Each chair has a solid beech frame making it both durable and sturdy. Add some comfort with a seat and back pad. Bring some colour with your choice of fabric and frame finish. This range includes a dining chair, easy chair, armchair, high stool and a counter height stool.",
    features: [
      { name: "Solid beech frame." },
      { name: "A selection of colour finishes." },
      { name: "Polished veneer seat." },
     
    ],
    extras: [
      { name: "Upholstered Seat Pad." },
      { name: "Upholstered Back Pad." },
    ],
    dimensions: [
      "TRASTOL LOUNGE",
      "Overall Width: 620mm",
      "Overall Height: 700mm",
      "Depth: 610mm",
      "Seat Height: 400mm",
      "TRASTOL EASY",
      "Overall Width: 540mm",
      "Overall Height: 700mm",
      "Depth: 550mm",
      "Seat Height: 400mm",
      "TRASTOL DINING",
      "Overall Width: 540mm",
      "Overall Height: 780mm",
      "Depth: 500mm",
      "Seat Height: 450mm",
      "TRASTOL COUNTER",
      "Overall Width: 500mm",
      "Overall Height: 900mm",
      "Depth: 500mm",
      "Seat Height: 640mm",
      "TRASTOL HIGH",
      "Overall Width: 500mm",
      "Overall Height: 1000mm",
      "Depth: 500mm",
      "Seat Height: 740mm",
    ],
    rangeImg: "/images/Trastol/TrastolRange.png",
    slideImgs: [
      { src: "/images/Trastol/TrastolEasy.png", caption: "Trastol Easy" },
      { src: "/images/Trastol/TrastolDining.png", caption: "Trastol Dining" },
      { src: "/images/Trastol/TrastolLounge.png", caption: "Trastol Lounge" },
    ],
    situImg: "",
    pdfDownload: "/assets/SpecSheets/TrastolSpecs.pdf",
  },
  {
    name: "Ekol Range",
    slug: "ekol-range",
    shortDescription:
      "Smooth lines and a sleek finish bring this classic chair design to contemporary spaces.",
    longDescription:
      "The Ekol range combines a classic chair design with minimalist chic. Built to be strong, the Ekol range is perfect for frequent use in break out areas, canteens and restaurants. This range includes a dining chair, a high stool and a mid-height stool.",
    features: [
      { name: "Solid beech frame." },
      { name: "A selection of colour finishes." },
      { name: "Polished veneer seat." },
    ],
    extras: [
      { name: "Upholstered Seat Pad." },      
    ],
    dimensions: [
      "EKOL HIGH STOOL",
      "Overall Width: 470mm",
      "Overall Height: 1000mm",
      "Depth: 470mm",
      "Seat Height: 740mm",
      "EKOL MID STOOL",
      "Overall Width: 470mm",
      "Overall Height: 900mm",
      "Depth: 470mm",
      "Seat Height: 640mm",
      "EKOL DINING CHAIR",
      "Overall Width: 470mm",
      "Overall Height: 790mm",
      "Depth: 510mm",
      "Seat Height: 460mm",
    ],
    rangeImg: "/images/Ekol/EkolRange.png",
    slideImgs: [
      { src: "/images/Ekol/EkolSimpleDining.png", caption: "Ekol Dining" },
      { src: "/images/Ekol/EkolSimpleHigh.png", caption: "Ekol High Stool" },
      { src: "/images/Ekol/EkolSimpleMid.png", caption: "Ekol Mid Stool" },
    ],
    situImg: "/images/Ekol/EkolSitu.jpg",
    pdfDownload: "/assets/SpecSheets/TrastolSpecs.pdf",
  },
  {
    name: "Abbor Range",
    slug: "abbor-range",
    shortDescription:
      "Comfortable, classic and simply beautiful. The stool you were looking for!",
    longDescription:
      "The Abbor range's simple design allows it to fit into any space. Perfect for meeting rooms, break-out spaces, canteens, receptions and many more. Choose from a Low stool, Mid-Height Stool, and High Stool.",
    features: [
      { name: "Solid beech frame." },
      { name: "A selection of colour finishes." },
      { name: "Polished veneer seat." },
    ],
    extras: [
      { name: "Upholstered Seat Pad." },      
    ],
    dimensions: [
      "ABBOR HIGH",
      "Overall Width: 430mm",
      "Overall Height: 740mm",
      "Depth: 440mm",
      "Seat Height: 740mm",
      "ABBOR MID",
      "Overall Width: 420mm",
      "Overall Height: 640mm",
      "Depth: 400mm",
      "Seat Height: 640mm",
      "ABBOR LOW",
      "Overall Width: 420mm",
      "Overall Height: 450mm",
      "Depth: 370mm",
      "Seat Height: 450mm",
    ],
    rangeImg: "/images/Abbor/AbborRange.png",
    slideImgs: [
      { src: "/images/Abbor/AbborPerchHigh.png", caption: "Abbor High" },
      { src: "/images/Abbor/AbborPerchMid.png", caption: "Abbor Mid" },
      { src: "/images/Abbor/AbborPerchLow.png", caption: "Abbor Low" },
    ],
    situImg: "/images/Abbor/AbborSitu.jpg",
    pdfDownload: "/assets/SpecSheets/TrastolSpecs.pdf",
  },
];

export default products;

// {
//   name: ' RANGE',
//   slug: '-range',
//   shortDescription: '',
//   longDescription: '',
//   features: [
//    {name: },
//   ],
//   extras: [
//    {name: },
//   ],
//   dimensions: [
//     'Overall Height: ',
//     'Headrest Height: ',
//     'Overall Width: ',
//     'Overall Depth: ',
//     'Seat Width: ',
//     'Seat Depth: ',
//     'Seat Height: ',
//     'Weight Capacity: ',
//   ],
//   rangeImg: '/images//.png',
//   slideImgs: [
//     '/images//.png',
//     '/images//.png',
//     '/images//.png',
//     '/images//.png',
//   ],
//   pdfDownload: ''
// },
