import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../../styles.css";

const ProjectspageCarousel = () => {
  const projects = [
    "/images/Projectspageimgs/AstonMartin.jpg",
    "/images/Projectspageimgs/Boardroom1.jpg",
    "/images/Projectspageimgs/Boardroom2.jpg",
    "/images/Projectspageimgs/BristolKooche.jpg",
    "/images/Projectspageimgs/Canteen.jpg",
    "/images/Projectspageimgs/HighSofa1.jpg",
    "/images/Projectspageimgs/HighSofa2.jpg",
    "/images/Projectspageimgs/HighSofa3.jpg",
    "/images/Projectspageimgs/HighStool.jpg",
    "/images/Projectspageimgs/Iseo1.jpg",
    "/images/Projectspageimgs/Iseo2.jpg",
    "/images/Projectspageimgs/Kooche.jpg",
    "/images/Projectspageimgs/LongSofa.jpg",
    "/images/Projectspageimgs/Lundy.jpg",
    "/images/Projectspageimgs/Lynx1.jpg",
    "/images/Projectspageimgs/Lynx2.jpg",
    "/images/Projectspageimgs/Tubs1.jpg",
    "/images/Projectspageimgs/Tubs2.jpg",
    "/images/Projectspageimgs/Tubs3.jpg",
    "/images/Projectspageimgs/Tubs4.jpg",
    "/images/Projectspageimgs/Tubs5.jpg",
    "/images/Projectspageimgs/Tubs6.jpg",
    "/images/Projectspageimgs/Uno1.jpg",
    "/images/Projectspageimgs/Uno2.jpg",
  ];

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Carousel
        fade
        className="projectscarousel-size"
        indicators={false}
        activeIndex={index}
        onSelect={handleSelect}
      >
        {projects.map((project, idx) => (
          <Carousel.Item key={idx}>
            <img className="projectsimg-size" src={project} alt={`Project ${idx + 1}`} />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="project-thumbnail-scroll-container">
        <div className="project-thumbnail-container">
          {projects.map((project, idx) => (
            <img
              key={idx}
              src={project}
              alt={`Thumbnail ${idx}`}
              className={`project-thumbnail ${idx === index ? "active" : ""}`}
              onClick={() => setIndex(idx)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectspageCarousel;
