import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Accordion from "react-bootstrap/Accordion";
import ContactForm from "./ContactForm";
import "../../styles.css";

function CustomerServicespageAccordian() {
  return (
    <Accordion defaultActiveKey="0" className="txtalign-left" alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Contact Us</Accordion.Header>
        <Accordion.Body>
          <p>
            Contact us by any of the means listed, or use our form below and we will endeavour to answer your query.
          </p>
          <p>
            <b>Office Hours:</b> Monday-Friday 9am-5pm
          </p>
          <p>
            <b>Email:</b> enquiries@adato.co.uk
          </p>
          <p>
            <b>Telephone:</b> +44 7375122636
          </p>
          <p>
            <b>Address:</b> the office @ 84 the common Melksham, Wiltshire, SN12
            8ND, UK
          </p>
          <br></br>
          <p>
            <b>Contact Form</b>
          </p>          
          <Container>
            <Row className="mt-2">
              <Col md={{ span: 6, offset: 0 }}>
                <ContactForm />
              </Col>
            </Row>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>FAQs</Accordion.Header>
        <Accordion.Body>
          <p>
            If you have any questions or queries then please don't hesitate to
            get in touch using the information above.
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Adjustment & Assembly Guides</Accordion.Header>
        <Accordion.Body>
          <p>
            If we deliver your chair via courier, it may need assembling. Follow
            the steps below to get you started!
          </p>
          <p>
            We'll add some video guides on detailed product adjustment in due
            course; however for now if you have any additional queries please
            get in touch using the contact form above.
          </p>
          <div className="centered-image-container">
            <Image
              src="/images/CSpageimgs/chairAssembly.png"
              className="customerservices-img"
              fluid
            />
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Warranty</Accordion.Header>
        <Accordion.Body>
          <p>
            All our products come with a 5-year* structural guarantee, excluding
            fair wear and tear, damage or failure caused by misuse or abuse. Any
            product that may need repair under the Terms of our guarantee will
            be collected from and returned to the supplying dealer on our next
            available transport or, if more appropriate, fixed on site.
          </p>
          <p>
            The guarantee applies for daily use of up to 8 hours a day by users
            up to *150kg.
          </p>
          <p>
            <b>Further Terms:</b>
          </p>
          <p>
            <i>
              *Adato undertakes that if: 1) within 5 years of delivery of the
              goods, the structure of the goods (excluding upholstery) or 2)
              within 2 years of delivery of the goods the Upholstery is found
              upon inspection by or on behalf of the company to have proved
              defective in workmanship or materials under normal use and service
              (8hr day usage) it will (at its option) either repair or replace
              the defective goods or parts thereof or credit the price paid by
              the buyer to the company for such defective goods or parts
              PROVIDED THAT in any case the original Goods have been accepted
              and paid for and PROVIDED ALSO THAT the Goods have been properly
              maintained and have not been subject to any misuse, unauthorised
              repair, replacement, modification or alteration from its original
              accepted condition. Any product that may need repair under the
              Terms of our guarantee will be collected from, and returned to,
              the supplying Dealer on our next available transport, OR if more
              appropriate, fixed on site at Adato's earliest convenience.
              Adato's liability is limited to such replacement or repair which
              shall be deemed a complete fulfilment. Adato shall be under no
              liability whatsoever for any consequential damage, loss or other
              expenses whether arising out of contract, negligence or otherwise.
              Adato will replace any items that are defective through the
              Buyer's misuse at the current price chargeable or will undertake
              to repair the same at a charge to the Buyer.
            </i>
          </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Delivery & Returns</Accordion.Header>
        <Accordion.Body>
          <p>Free UK mainland delivery on orders above £1,000*</p>
          <p>
            <b>Direct Deliveries:</b>
          </p>
          <p>
            We would also be delighted to make on time, direct to the door,
            cost-effective, tailgate deliveries to your customer's premises too.
          </p>
          <p>
            This service comes at a bespoke cost subject to the delivery
            location and can include chair assembly, and set-up assistance.
          </p>
          <p>
            We only charge you what it cost us to make the delivery. Please
            contact us to discover relevant postcode charges.
          </p>
          <p>
            <b>Further terms:</b>
          </p>
          <p>
            <i>
              *All prices include FOC deliveries to our nationwide network of
              dealerships premises, subject to a minimum net order value of
              £1,000. All UK Islands, Scotland, Ireland & mainland Europe will
              be subject to further specific minimum order values or charges.
              All these routes can be prone to fluctuations in
              ferry/border/freight costs. Please contact us for current delivery
              charges on all above routes.
            </i>
          </p>
          <p>
            <i>
              *UK Mainland exclude Highlands and all offshore UK Islands a) All
              UK Mainland orders above the net value of £1000.00 shall be FOC.
              Orders below the net value of £1000.00 shall be subject to a
              delivery charge of a minimum of £40 + VAT and will be calculated
              on an order by order basis. b) UK mainland deliveries will be
              tailgate deliveries only. The driver making the delivery will be
              unaccompanied and the purchaser is requested to provide free
              assistance in unloading the said delivery. c) Delivery shall be
              deemed as completed on acceptance, Signatured paperwork, at the
              tailgate delivery point and risk shall pass to the Buyer. d) Delay
              in transport or delivery of any particular instalment shall not
              entitle the buyer to repudiate the whole contract nor relieve the
              Buyer of their obligation to accept and pay for the remaining
              instalments. e) Risk of loss or damage in transit will be borne by
              the party paying freight charges. f) Adato shall endeavour to make
              deliveries within a reasonable time. HOWEVER, ADATO SHALL NOT BE
              LIABLE TO THE BUYER FOR ANY DAMAGES, WHETHER INCIDENTAL,
              CONSEQUENTIAL OR OTHERWISE, FOR FAILURE TO FILL ORDERS, DELAYS IN
              DELIVERY OR ANY ERROR IN THE FILING OF ORDERS. g) Time of delivery
              shall not be of the essence to any contract to which these Terms
              and Conditions apply and shall not be made so by the service of
              any notice.
            </i>
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default CustomerServicespageAccordian;
