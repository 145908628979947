const aboutcontents = [
  {
    title: "Passionate about Seating",
    description:
      "Assembling and manufacturing chairs in the heart of Wiltshire since 2019. Adato are a driven and motivated team who are passionate about what they do and who they work with. We benefit from a wealth of connections and positive working relationships.\nSo whether your requirement is for high quality bespoke furniture or the more affordable and large scale ranges, we have something to offer you.",
    aboutContentImg: "/images/Aboutpageimgs/AboutWarehouse.jpg",
  },
  {
    title: "40 Years In the Industry",
    description:
      "Adato is led by office furniture veteran Gus Gurney.\nSince entering the furniture industry in 1978, Gus has been the key instigator for the sale and manufacture of over 2 million units of seating. His wealth of experience has secured repeat business across 400+ nationwide furniture dealers, UK educational supply chain, commercial fit out companies and large construction firms. To date, his most memorable project has been overseeing the installation of 3500+ operator chairs on two Royal Navy aircraft carriers.",
    aboutContentImg: "/images/Aboutpageimgs/AboutGus.jpg",
  },
  {
    title: "A Family Business",
    description:
      "Gus leads a business which is relationship focused; he is passionate about designing and developing products that are the right fit for his clients.\nHe works alongside multiple members of the Gurney family. Together with the wider team, they make up the Adato Family.",    aboutContentImg: "/images/Aboutpageimgs/AboutAssembling.jpg",
  },
  {
    title: "Environmentally Conscious",
    description:
      "We may only be a small firm, but we will always aim to base our business decisions on what we can do to lessen our impact on the environment.",
    aboutContentImg: "/images/Aboutpageimgs/AboutSally.jpg",
  },
];

export default aboutcontents;
