import React from "react";
import "../styles.css";

const Register = () => {
  return (
    <div className="page-content">
      <h2>Register Page</h2>
      <p>Under Construction...</p>
    </div>
  );
};

export default Register;
