import React from "react";
import CustomerservicespageAccordian from "../components/customerservicespage-components/CustomerservicespageAccordian";
import "../styles.css";

const CustomerServices = () => {
  return (
    <div className="page-content">
      <h2>Customer Services</h2>
      <CustomerservicespageAccordian />
    </div>
  );
};

export default CustomerServices;
