import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "../../styles.css";

const HomepageScrollButton = () => {
  const [selected, setSelected] = useState(false);

  const handleScroll = () => {
    const scrollAdjust = document.body.scrollHeight / 2.9;

    window.scrollTo({
      top: scrollAdjust,
      behavior: "smooth",
    });

    setSelected(true);

    setTimeout(() => {
      setSelected(false);
    }, 1000);
  };

  return (
    <Button
      className={`scroll-button ${selected ? "selected" : ""}`}
      variant="primary"
      onClick={handleScroll}
    >
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 16 16"
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
      </svg>{" "}
    </Button>
  );
};

export default HomepageScrollButton;
